export interface PaginationMeta {
  current_page: number
  from: number
  last_page: number
  to: number | null
  total: number
  per_page?: number
  count?: number
}

export class Pagination implements PaginationMeta {
  public current_page
  public from
  public last_page
  public to
  public total
  public per_page
  public count

  constructor(partialOptions: Partial<PaginationMeta> = {}) {
    // merge default values with user overridden values
    // & assign to the instance so we can reset to them later
    this.defaultValues = { ...this.defaultValues, ...partialOptions }

    // must manually assign each property so the linter knows they have default values
    this.current_page = this.defaultValues.current_page
    this.from = this.defaultValues.from
    this.last_page = this.defaultValues.last_page
    this.to = this.defaultValues.to
    this.total = this.defaultValues.total
    this.per_page = this.defaultValues.per_page
    this.count = this.defaultValues.count
  }

  private defaultValues: PaginationMeta = {
    current_page: 1,
    from: 1,
    last_page: 0,
    to: 0,
    total: 0,
    per_page: 10,
    count: undefined,
  }

  public update(partialOptions: Partial<PaginationMeta> = {}) {
    Object.assign(this, partialOptions)
  }

  public reset() {
    Object.assign(this, this.defaultValues)
  }
}
